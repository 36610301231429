<template>
  <StSidebarItem
    v-for="item in items"
    :id="`sidebar-item-${item.entityId}`"
    :key="item.entityId"
    :model-value="activeEntityId === item.entityId"
    :label="item.name"
    :icon="item.icon as IconName"
    :counter="item.liveSportEventsNumber"
    :level="level"
    :link="item.link"
    :data-t="`sidebar-item-${item.entityId}`"
    @update:model-value="handleToggle(item, $event)"
  >
    <StSidebarList v-if="item.children?.length" :level="level + 1">
      <SportMenuList
        :items="item.children"
        :level="level + 1"
        @close-menu-tree="handleCloseMenu"
      />
    </StSidebarList>
  </StSidebarItem>
</template>

<script setup lang="ts">
import type { ExtendedMenuItem } from '@st/nuxt-markets-store/types'
import type { IconName } from '@st/ui/components/StIcon/types'

const props = defineProps<{
  items: ExtendedMenuItem[]
  level: number
}>()

const emit = defineEmits<{
  (e: 'closeMenuTree'): void
}>()

const activeEntityId = ref<string>()

function handleToggle(item: ExtendedMenuItem, newValue: boolean) {
  if (!newValue) {
    activeEntityId.value = undefined
  } else {
    activeEntityId.value = item.entityId
  }
}

const route = useRoute()

const routeParams = computed(() => {
  const { sportId, categoryId, tournamentId, eventId } = route.params

  const isCustomParam = (v: unknown) => String(v).includes('custom')
  const toNumberOrNull = (v: unknown) => (v ? Number(v) : null)

  return {
    sportId: isCustomParam(sportId) ? String(sportId) : toNumberOrNull(sportId),
    categoryId: toNumberOrNull(categoryId),
    tournamentId: toNumberOrNull(tournamentId),
    eventId: toNumberOrNull(eventId),
  }
})

function findAndOpenItem(type: ExtendedMenuItem['type'], id: number | string) {
  const item = props.items.find(
    (entity) => entity.type === type && entity.id === id,
  )
  if (!item) return false
  handleToggle(item, true)
  return true
}

/* 
  функция закрытия ветки дерева меню у матчей
  которые были перенесены в кастомные разделы
  (так как у матчей ссылки ведут на оригинальный спорт)
*/
function handleCloseMenu() {
  activeEntityId.value = undefined
  emit('closeMenuTree')
}

const { items: menuItems } = toRefs(props)
watch(
  [routeParams, menuItems],
  async ([newParams, newMenuItems]) => {
    if (!newMenuItems?.length) return

    const { sportId, categoryId, tournamentId, eventId } = newParams

    if (eventId && tournamentId && props.level === 4) {
      const isFound = findAndOpenItem('tournament', tournamentId)

      if (!isFound) {
        handleCloseMenu()
        return
      }
    }

    /* 
      проверку пунктов меню начинаем от самой нижней ветки (турнира)
      т.к они могут быть корневыми элементами после перемещения в кастомы
    */
    if (tournamentId) {
      const isFound = findAndOpenItem('tournament', tournamentId)
      if (isFound) return
    }

    if (categoryId) {
      const isFound = findAndOpenItem('category', categoryId)
      if (isFound) return
    }

    if (sportId) {
      const isFound =
        findAndOpenItem('custom', sportId) || findAndOpenItem('sport', sportId)
      if (isFound) return
    }

    activeEntityId.value = undefined
  },
  {
    immediate: true,
  },
)
</script>
