<template>
  <StSidebarItem
    v-model="isActive"
    :label="t('menu.cybersport')"
    icon="cybersport"
    with-chevron
    :level="1"
    link="/cybersport"
    data-t="sidebar-item-cybersport"
  >
    <StSidebarList :level="2">
      <StSidebarItem
        :label="t('menu.live')"
        icon="play-solid"
        :counter="liveCounter"
        :level="2"
        link="/cybersport/live"
        data-t="sidebar-item-cybersport-live"
        :model-value="route.path === '/cybersport/live'"
      />
      <StSidebarItem
        :label="t('menu.upcoming')"
        icon="timer"
        :level="2"
        link="/cybersport/upcoming"
        data-t="sidebar-item-cybersport-upcoming"
        :model-value="route.path === '/cybersport/upcoming'"
      />
    </StSidebarList>
    <StSidebarList v-if="cyberSportmenu.length" :level="2">
      <SportMenuList :items="cyberSportmenu" :level="2" />
    </StSidebarList>
  </StSidebarItem>
</template>

<script setup lang="ts">
import { CYBER_SPORT_ID } from 'markets-store/constants'
import SportMenuList from '../SportMenu/SportMenuList.vue'

type OpenedMenuItem = 'cybersport' | null

const { t } = useI18n()

const { menu } = useCybersportMenu()
const cyberSportmenu = computed(() => menu.value[0]?.children ?? [])

const props = defineProps<{
  modelValue: boolean
}>()

const emit = defineEmits<{
  (e: 'update:modelValue', value: OpenedMenuItem): void
}>()

const isActive = computed<boolean>({
  get: () => props.modelValue,
  set: (value) => emit('update:modelValue', value ? 'cybersport' : null),
})
const route = useRoute()

const liveCyberSportEvents = useSportEventsBySportId(CYBER_SPORT_ID, 'live')
const liveCounter = computed(() => liveCyberSportEvents.value.length)
</script>
