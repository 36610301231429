<template>
  <StSidebarItem
    v-model="isActive"
    :label="t('menu.sport')"
    icon="all-sports"
    :level="1"
    link="/sport"
    with-chevron
    data-t="sidebar-item-sport"
  >
    <StSidebarList :level="2">
      <StSidebarItem
        :label="t('menu.all')"
        icon="list"
        :level="2"
        link="/sport/all"
        data-t="sidebar-item-sport-all"
      />
      <StSidebarItem
        :label="t('menu.live')"
        icon="play-solid"
        :counter="liveCounter"
        :level="2"
        link="/sport/live"
        data-t="sidebar-item-sport-live"
      />
      <StSidebarItem
        :label="t('menu.upcoming')"
        icon="timer"
        :level="2"
        link="/sport/upcoming"
        data-t="sidebar-item-sport-upcoming"
      />
    </StSidebarList>

    <StSidebarList :level="2">
      <SportMenuList
        :items="menu"
        :level="2"
        @close-menu-tree="handleCloseMenuTree"
      />
    </StSidebarList>
  </StSidebarItem>
</template>

<script setup lang="ts">
import SportMenuList from './SportMenuList.vue'

type OpenedMenuItem = 'sport' | null

const { menu } = useSportMenu()
const { t } = useI18n()

const props = defineProps<{
  modelValue: boolean
}>()

const emit = defineEmits<{
  (e: 'update:modelValue', value: OpenedMenuItem): void
}>()

const liveEvents = useLiveEvents()

const liveCounter = computed(() => liveEvents.value.length)

function handleCloseMenuTree() {
  emit('update:modelValue', 'sport')
}

const isActive = computed<boolean>({
  get: () => props.modelValue,
  set: (value) => emit('update:modelValue', value ? 'sport' : null),
})
</script>
