<template>
  <div class="active-bets">
    <div v-if="extendedBets.length" class="bets-wrapper">
      <CouponScrollable class="scrollable-bets">
        <ActiveBet v-for="item in extendedBets" :key="item.id" :bet="item" />
        <div ref="endOfTheList" />
      </CouponScrollable>
      <div class="empty-actions">
        <StButton
          block
          type="gray"
          :label="t('coupon.goToMyBets')"
          to="/personal/bets"
        />
      </div>
    </div>
    <div v-else class="empty">
      <StSpinner v-if="pending" :size="16" />
      <EmptyCoupon v-else class="empty-coupon" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useInfiniteScroll } from '@st/use/composables'
import { useFindHistoryBets } from '../../composables/useFindHistoryBets'
import ActiveBet from '../ActiveBet/ActiveBet.vue'
import EmptyCoupon from '../EmptyCoupon/EmptyCoupon.vue'
import CouponScrollable from '../CouponScrollable/CouponScrollable.vue'

const { t } = useI18n()

const endOfTheList = ref<HTMLDivElement>()

const { canLoadMore, loadMoreBets, reset, extendedBets, pending } =
  useFindHistoryBets()

useInfiniteScroll({
  el: endOfTheList,
  loadMore: async () => {
    if (canLoadMore.value) {
      await loadMoreBets()
    }
  },
  disabledObserver: computed(() => !canLoadMore.value),
})
onActivated(() => {
  reset()
})
</script>

<style scoped>
.active-bets,
.bets-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.empty {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 242px;
}

.empty-coupon {
  flex-grow: 1;
}

.scrollable-bets {
  display: flex;
  flex-shrink: 1;
  min-height: 0;
  padding: var(--spacing-150);
}

.empty-actions {
  display: flex;
  gap: var(--spacing-100);
  justify-content: center;
  padding: var(--spacing-150);
}
</style>
