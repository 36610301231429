<template>
  <StSidebarItem
    v-model="isActive"
    :label="t('menu.casino')"
    icon="casino"
    :level="1"
    link="/casino"
    with-chevron
    data-t="sidebar-item-casino"
  >
    <StSidebarList :level="2">
      <StSidebarItem
        v-for="category in extendedTopCategories"
        :key="category.code"
        :label="category.name"
        :icon-raw="category.icon"
        :icon="category.iconName"
        :level="2"
        :link="category.link || `/casino/categories/${category.code}`"
        data-t="sidebar-item-casino-all"
      />
    </StSidebarList>
    <StSidebarList :level="2">
      <StSidebarItem
        v-for="category in bottomCategories"
        :key="category.code"
        :label="category.name"
        :icon-raw="category.icon"
        :level="2"
        :link="`/casino/categories/${category.code}`"
        data-t="sidebar-item-casino-all"
      />
      <StSidebarItem
        :label="t('menu.providers')"
        icon="providers"
        :level="2"
        link="/casino/providers/"
      />
    </StSidebarList>
  </StSidebarItem>
</template>

<script setup lang="ts">
import StSidebarList from '@st/ui/components/StSidebarList/StSidebarList.vue'
import StSidebarItem from '@st/ui/components/StSidebarItem/StSidebarItem.vue'
import { useCasinoMenu } from './useCasinoMenu'

const { t } = useI18n()

const { extendedTopCategories, bottomCategories } = useCasinoMenu()

const props = defineProps<{
  modelValue: boolean
}>()

type OpenedMenuItem = 'casino' | null

const emit = defineEmits<{
  (e: 'update:modelValue', value: OpenedMenuItem): void
}>()

const isActive = computed<boolean>({
  get: () => props.modelValue,
  set: (value) => emit('update:modelValue', value ? 'casino' : null),
})
</script>
