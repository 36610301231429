<template>
  <header class="header" :class="{ open: isOpen }" @click="isOpen = true">
    <button
      class="header-tab"
      :class="{ active: tab === 'coupon', open: isOpen }"
      @click="tab = 'coupon'"
    >
      {{ t('coupon.coupon') }}
      <StCounter v-bind="getCounterProps('coupon')" :label="outcomeCount" />
    </button>
    <button
      class="header-tab"
      :class="{ active: tab === 'bets', open: isOpen }"
      data-t="coupons-in-game-tab-uttn"
      @click="tab = 'bets'"
    >
      {{ t('coupon.inPlay') }}
      <StCounter
        v-bind="getCounterProps('bets')"
        :label="betCounter"
        data-t="in-game-bets-counter-7nrc"
      />
    </button>
    <button
      class="chevron-button"
      :class="{ open: isOpen }"
      @click.stop="isOpen = !isOpen"
    >
      <StIcon :name="isOpen ? 'chevron-down' : 'chevron-top'" size="16" />
    </button>
  </header>
</template>

<script setup lang="ts">
import { useCouponStore } from '../../stores/useCouponStore'
import { useActiveBetsStore } from '../../stores/useActiveBetsStore'

const { t } = useI18n()

const props = defineProps<{
  isOpen: boolean
  tab: 'coupon' | 'bets'
}>()

const emit = defineEmits<{
  (e: 'update:isOpen', value: boolean): void
  (e: 'update:tab', value: 'coupon' | 'bets'): void
}>()

const isOpen = computed({
  get: () => props.isOpen,
  set: (value) => emit('update:isOpen', value),
})

const tab = computed({
  get: () => props.tab,
  set: (value) => emit('update:tab', value),
})

const { outcomeCount } = storeToRefs(useCouponStore())
const { betCounter } = storeToRefs(useActiveBetsStore())

function getCounterProps(counterTab: 'coupon' | 'bets'): {
  color: 'black' | 'white'
  bgColor: 'black' | 'white'
} {
  if (!isOpen.value) {
    return {
      color: 'black',
      bgColor: 'white',
    }
  }

  const isActiveTab = counterTab === props.tab
  return isActiveTab
    ? {
        color: 'black',
        bgColor: 'white',
      }
    : {
        color: 'white',
        bgColor: 'black',
      }
}
</script>

<style scoped>
.header-tab {
  all: unset;

  display: flex;
  flex-basis: 0;
  flex-grow: 1;
  gap: var(--spacing-075);
  align-items: center;
  justify-content: center;

  min-width: 0;
  padding: var(--spacing-150) var(--spacing-050);

  font: var(--desktop-text-md-medium);

  border-radius: var(--border-radius-100, 8px);

  &:active {
    background-color: rgb(214 78 0 / 60%);
  }

  &.open {
    &:hover {
      background-color: var(--button-gray-default);
    }

    &:active {
      background: rgb(57 57 70 / 60%);
    }

    &.active {
      &:hover {
        background-color: var(--button-primary-default);
      }
    }
  }

  &.active {
    background-color: var(--button-primary-default);
  }

  &:hover {
    background-color: var(--button-primary-active);
  }
}

.chevron-button {
  all: unset;

  padding: var(--spacing-200);

  line-height: 0;
  color: var(--text-primary);

  border-radius: var(--border-radius-100);

  transition: background-color 0.1s;

  &:hover {
    background-color: var(--button-primary-active);
  }

  &:active {
    background-color: rgb(214 78 0 / 60%);
  }

  &.open {
    &:hover {
      background-color: var(--button-gray-default);
    }

    &:active {
      background: rgb(57 57 70 / 60%);
    }
  }
}

.header {
  cursor: pointer;

  position: relative;
  top: 0;
  right: 0;
  left: 0;

  display: flex;
  gap: var(--spacing-050);
  align-items: center;

  padding: var(--spacing-050);

  background-color: var(--button-primary-default);

  transition: background-color 0.2s;

  &.open {
    background-color: var(--background-secondary);

    .header-tab {
      padding: var(--spacing-075) var(--spacing-050);
    }

    .chevron-button {
      padding: var(--spacing-125);
      color: var(--text-secondary);
    }
  }
}
</style>
